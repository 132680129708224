import React from 'react';
import PropTypes from 'prop-types';

import {
  RecommendedGames as RecommendedGamesCore
} from 'core/components';
import { withCoreComponent } from 'core/hocs';
import { GAMES_CATEGORY_PAGE } from 'core/constants';

import { GamesCarousel } from 'components/games-carousel/games-carousel';
import { GameSectionHeader } from 'components/game-section-header/game-section-header';

import './recommended-games.scss';

export const RecommendedGamesUI = ({
  games,
  isUserLoggedIn,
  className,
  isGamePage,
}) => (
  <div className={className}>
    <GameSectionHeader
      route={`/category/${GAMES_CATEGORY_PAGE.RECOMMENDED}`}
      headerKey="games.recommended"
    />
    {/* MOBILE */}
    <GamesCarousel
      isUserLoggedIn={isUserLoggedIn}
      isGamePage={isGamePage}
      games={games}
      isArrowsToShow={false}
    />
  </div>
);

RecommendedGamesUI.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  games: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  isGamePage: PropTypes.bool
};

RecommendedGamesUI.defaultProps = {
  games: [],
  className: null,
  isGamePage: false
};

export const RecommendedGames = withCoreComponent(
  RecommendedGamesCore,
  RecommendedGamesUI
);
