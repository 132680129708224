import React from 'react';
import PropTypes from 'prop-types';

import { GamesCarousel } from 'components/games-carousel/games-carousel';
import { GameSectionHeader } from 'components/game-section-header/game-section-header';

import '../all-games.scss';

export const LoggedIn = ({ games }) => (
  <div>
    <GameSectionHeader className="games-title" route="/games" headerKey="all-games" />

    <GamesCarousel
      isArrowsToShow={false}
      games={games}
      rows={2}
      isUserLoggedIn
      category="all"
    />
  </div>
);

LoggedIn.propTypes = {
  games: PropTypes.arrayOf(PropTypes.shape),
};

LoggedIn.defaultProps = {
  games: [],
};

